<template>
  <v-container
    class="content-editor relative mx-2"
    :fluid="schema.full"
    style="min-height: calc(100vh - 64px);"
    @click.stop.prevent="focusLastLine"
  >
    <div style="position: absolute; right: 0; top: 0;">
      <v-menu min-width="auto" offset-y left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-switch v-model="schema.full" /> Teljes szélesség
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <h1
      contenteditable="true"
      placeholder="Untitled"
      @keydown.enter.prevent.stop="addNewLine(-1)"
      class="my-1"
    >
      {{ schema.title }}
    </h1>

    <div class="content-editor-content">
      <component
        v-for="(content, index) in schema.content"
        :key="content.id"
        :is="`editor-element-${content.type}`"
        :ref="`component-${index}`"
        v-model="content.content"
        @enter="addNewLine(index)"
        @indent="indentLine(index)"
        @unindent="unindentLine(index)"
        class="my-1"
        :style="`padding-left: ${content.indent * 1.5}em;`"
      />
    </div>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Text from './elements/Text.vue';

export default {
  components: {
    EditorElementText: Text,
  },

  data() {
    return {
      schema: {
        title: 'Test',
        full: true,
        content: [
          {
            id: uuidv4(),
            type: 'text',
            content: 'test text',
            indent: 0,
          },
        ],
      },
    };
  },

  methods: {
    addNewLine(index) {
      let indent = 0;

      if (index > 0) {
        indent = this.schema.content[index - 1].indent;
      }

      this.schema.content.splice(index + 1, 0, {
        id: uuidv4(),
        type: 'text',
        content: '',
        indent,
      });
      this.$nextTick(() => {
        this.$refs[`component-${index + 1}`][0].$el.focus();
      });
    },

    focusLastLine(e) {
      if (e.target !== e.currentTarget) return;

      const node = this.$refs[`component-${this.schema.content.length - 1}`][0].$el.childNodes[0];
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNode(node);
      range.setStart(node, 0);
      range.setEnd(node, node.textContent.length);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    },

    indentLine(index) {
      if (index <= 0) return;

      const max = this.schema.content[index - 1].indent + 1;

      if (this.schema.content[index].indent < max) {
        this.schema.content[index].indent += 1;
      }
    },

    unindentLine(index) {
      if (this.schema.content[index].indent > 0) {
        this.schema.content[index].indent -= 1;
      }
    },
  },
};
</script>

<style>
.content-editor [placeholder]:empty:before {
    content: attr(placeholder);
    color: #555;
}

.content-editor [contenteditable]:focus-visible {
    outline: 0;
}
</style>
