<template>
  <div
    contenteditable="true"
    v-once
    @input="$emit('input', $event.target.innerText)"
    @keydown.enter.exact.prevent.stop="$emit('enter')"
    @keydown.tab.prevent.exact="$emit('indent')"
    @keydown.tab.shift.prevent.exact="$emit('unindent')"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
