<template>
  <layout title="Dokumentumaim">
    <template v-slot:menu>
      <v-spacer />
    </template>

    <template v-slot:content>
      <editor />
    </template>
  </layout>
</template>

<script>
import Editor from '../components/documents/editor/Editor.vue';
import Layout from '../layouts/Main.vue';

export default {
  components: { Layout, Editor },
};
</script>
